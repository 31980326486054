let baseUrl,onlineUrl='';
if (window.location.origin.indexOf('chinazy.org') > 0) {
  // baseUrl="http://jsadmin.chinazy.org";
  // onlineUrl='http://jsh5.chinazy.org'
  baseUrl="https://jsh5.chinazy.org/api"
  onlineUrl="https://jsh5.chinazy.org"
} else if (window.location.origin.indexOf('test.goago.cn') > 0) {
  // baseUrl='http://edu.test.goago.cn';
  baseUrl='https://jsh5.chinazy.org/api';
  onlineUrl='http://feeduh5.test.goago.cn'
} else if (window.location.origin.indexOf('dev.goago.cn') > 0) {
  baseUrl='http://edu.dev.goago.cn';
  onlineUrl='http://feeduh5.dev.goago.cn'
}else{
  // baseUrl='http://edu.test.goago.cn'
  baseUrl='https://jsh5.chinazy.org/api';
}
export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: '学会教育H5',
    /**
     * @description token在Cookie中存储的天数，默认1天
     */
    cookieExpires: 1,

    /**
     * @description api请求基础路径
     */
    baseUrl: {
      dev: baseUrl,
      onlineUrl:onlineUrl,
      pro: ''
    },

  }
